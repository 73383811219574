<template>
  <div id="dashboard">
  </div>
</template>

<script>
export default {
  name: "dashboard"
};
</script>

<script setup>
import { reactive, ref, onBeforeMount, onMounted, watch, onActivated } from "vue";
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<style lang="scss" scoped>
#dashboard {
  height: 100vh;
}
</style>
